export function isMobile() {
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	return flag;
}

export function isIpadFun() {
	var ua = window.navigator.userAgent
	var IsIPad = false
	if (/ipad/i.test(ua)) {
		IsIPad = true
	}
	// iPad from IOS13
	var macApp = ua.match(/Macintosh/i) != null
	if (macApp) {
		// need to distinguish between Macbook and iPad
		var canvas = document.createElement('canvas')
		if (canvas != null) {
			var context =
				canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
			if (context) {
				var info = context.getExtension('WEBGL_debug_renderer_info')
				if (info) {
					var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL)
					if (renderer.indexOf('Apple') != -1) IsIPad = true
				}
			}
		}
	}
	return IsIPad;
}