<template>
	<div class="top">
		<div class="logo"><img src="@/assets/img/logo.png" /></div>
		<div class="webName">小寅编程笔记</div>
	</div>
</template>

<script>
	
</script>

<style>
	.top{width: 100vw; height: 10vw; background-color: #212428; box-shadow:0px 0px 10px #777; position: relative; z-index: 1; display: flex; justify-content: center; align-items: center;}
	.top div{margin: 0; padding: 0;}
	.logo{width: auto; height: 10vw; overflow: hidden; display: flex; align-items: center;}
	.logo img{width: 6vw; height: 6vw; border: 1px #FFF solid; border-radius:50%;}
	.webName{width: auto; height: 10vw; line-height: 10vw; font-size: 4vw; color: #FFF; text-shadow: 5px 5px 5px #777, 0px 0px 2px #777; text-indent: 0.5em;}
</style>