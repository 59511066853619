<template>
	<div class="foot">
			<!-- path和activeColor是传给子组件的自定义属性，子组件用props接收 -->
			<nav-item v-for="(item,index) in columns" :path="'/' + item.path" activeColor="#0FFFB7">{{item.name}}</nav-item>
	</div>
</template>

<script>
	import navItem from '@/components/top/navItem.vue'
	import {getColumns} from '@/network/top/top.js'
	
	export default {
		name: 'foot',
		data() {
			return {
				columns: []
			}
		},
		components: {
			navItem
		},
		created() {
			getColumns()
			.then(res => {
				//console.log(res.data.data)
				this.columns = res.data.data
			})
			.catch(err => {
				console.log(err)
			})
		}
	}
</script>

<style>
	.foot{width: 100vw; height: 10vw; display: flex; background-color: #212428; position: fixed; left: 0; bottom: 0; right: 0; box-shadow:0px 0px 10px #777;}
</style>