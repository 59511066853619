import store from '@/store';
import axios from 'axios'

export function request(config) {

		// 创建axios实例
		const instance = axios.create({
			baseURL: 'https://manage.mycodenote.cn',
			timeout: 5000,
		})
		
		// axios 拦截器
	
		// 发送请求拦截，用途：1.当config中有一些信息不符合服务器要求，需要修改或添加时。2.当需要在请求并返回结果前插入一些图标或动画时。3.一些特殊请求（比如登录的token）需要携带一些特殊信息。
		axios.interceptors.request.use(config => {
			return config; //拦截后必须返回这个数据，否则请求会中断并报错
		},err => {
			console.log(err)
		})
		
		// 响应拦截
		axios.interceptors.response.use(res => {
			return config.data; //拦截后必须返回这个数据，否则请求会中断并报错
		},err => {
			console.log(err)
		})
		
		return instance(config)

}