<template>
	<div class="webBody">
		<top></top>
		<!-- Vue3 transition和keep-alive必须被包在router-view标签内，否则会报警告 -->
		<router-view class="box" v-slot="{ Component }">
			<transition name="slide-left" mode="out-in">
				<keep-alive exclude="messages,content">
				  <component :is="Component"></component>
				</keep-alive>
			</transition>
		</router-view>
		
		<foot></foot>
	</div>
</template>

<script>
	// @符号相当于src目录别名
	import top from '@/components/top.vue'
	import foot from '@/components/foot.vue'
	import {isMobile} from '@/network/isMobile.js'
	import {isIpadFun} from '@/network/isMobile.js'
	
	export default {
		name: 'app',
		components: {
			top,
			foot
		},
		beforeCreate() {
			if(!isMobile() && !isIpadFun()){
				if (window.location.pathname.indexOf("content") !== -1) {
					let lastPart = window.location.pathname.split('/').pop()
					window.location.replace('https://www.mycodenote.cn/content/index.php?id=' + lastPart)
				}
				else{
					window.location.replace('https://www.mycodenote.cn' + window.location.pathname)
				}
			}
		}
	}
</script>

<style lang="less">
@import url('@/assets/css/base.css');
.webBody{overflow: hidden;}		/* 隐藏滑动动画时的滚动条 */
.box{width: 100vw; height: auto; overflow: hidden; position: relative; min-height: calc(100vh - (10vw + 10vw));}
/* 定义滑动动画样式 */
.slide-left-enter-active {animation: slideLeftIn 0.5s; /* 自定义动画名称和时间 */}
 @keyframes slideLeftIn {
  0% { transform: translateX(100%); } /* 初始状态，将元素向右平移100% */
  100% { transform: translateX(0); } /* 结束状态，将元素返回原点 */
}
</style>
