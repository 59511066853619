import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		perPageNum: 12, //笔记每页显示条数
		noteId: JSON.parse(window.sessionStorage.getItem("noteId")) || 1
	},
	getters: {},
	mutations: {
		updataNoteId(state, id) {
			// 触发方法时改变mainTabs的值
			state.noteId = id
			// 将值存在缓存中，刷新时从缓存中获取
			window.sessionStorage.setItem("noteId", JSON.stringify(id))
		}
	},
	actions: {},
	modules: {}
})