<template>
	<div class="navTag" @click="itemClick" :style="activeStyle"><slot></slot></div>
</template>

<script>
	export default {
		name: 'navItem',
		props: {
			path: String,
			activeColor: {
				type: String,
				default: '#0FFFB7'
			}
		},
		computed: {
			isActive() {
				// 判断当前活跃的路由的path是否包含（是否相同）从父组件传入的path值，返回布尔值
				if(this.$route.path.indexOf(this.path) !== -1){
					return true
				}
				else if(this.$route.path.indexOf('content') !== -1 && this.path == '/notes'){
					return true
				}
			},
			activeStyle() {
				// 三元运算，判断isActive（上一个属性）是否处于活跃，如果是则执行问号后样式，不是则执行冒号后样式
				return this.isActive ? {color: this.activeColor} : {}
			}
		},
		methods: {
			itemClick() {
				this.$router.replace(this.path)
			}
		},
	}
</script>

<style>
	.navTag{flex: 1; text-align: center; height: 10vw; line-height: 10vw; font-size: 4vw; color: #FFF;}
	.navTag:nth-child(n+2) {border-left: 1px #777 solid;}
</style>