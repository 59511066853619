import {
	createRouter,
	createWebHistory
} from 'vue-router'

const notes = () => import('@/views/notes/notes.vue')
const documents = () => import('@/views/documents/documents.vue')
const tools = () => import('@/views/tools/tools.vue')
const messages = () => import('@/views/messages/messages.vue')
const content = () => import('@/views/content/content.vue')

const tools1 = () => import('@/views/tools/tools/rgb-16color.vue')
const tools2 = () => import('@/views/tools/tools/16color-rgb.vue')
const tools3 = () => import('@/views/tools/tools/md5_sha.vue')

const routes = [{
		path: '', 
		redirect: '/notes'
	},
	{
		path: '/notes',
		component: notes,
		meta: {
			title: '编程笔记-小寅编程笔记',
			description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
			keywords: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS'
		}
	},
	{
		path: '/documents',
		component: documents,
		meta: {
			title: '官方文档-小寅编程笔记',
			description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
			keywords: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，官方文档'
		}
	},
	{
		path: '/tools',
		component: tools,
		meta: {
			title: '实用工具-小寅编程笔记',
			description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
			keywords: 'RGB-HEX十六进制颜色互转，MD5/SHA加密，前端小工具'
		},
		children: [{
				path: '',
				redirect: '/tools/rgb-16color' // 重定向最前面的/不能省略，路径完整，否则报错
			},
			{
				path: 'rgb-16color',
				component: tools1,
				meta: {
					title: 'RGB转16进制颜色-小寅编程笔记',
					description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
					keywords: 'RGB-HEX十六进制颜色互转，MD5/SHA加密，前端小工具'
				}
			},
			{
				path: '16color-rgb',
				component: tools2,
				meta: {
					title: '16进制颜色转RGB-小寅编程笔记',
					description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
					keywords: 'RGB-HEX十六进制颜色互转，MD5/SHA加密，前端小工具'
				}
			},
			{
				path: 'md5_sha',
				component: tools3,
				meta: {
					title: 'MD5/SHA加密-小寅编程笔记',
					description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
					keywords: 'RGB-HEX十六进制颜色互转，MD5/SHA加密，前端小工具'
				}
			}
		]
	},
	{
		path: '/messages',
		component: messages,
		meta: {
			title: '留言交流-小寅编程笔记',
			description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
			keywords: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS'
		}
	},
	{
		path: '/content',
		redirect: '/notes'
	},
	{
		path: '/content/:id',
		component: content,
		meta: {
			description: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS等编程笔记分享；各类编程语言官方文档分享；前端编程实用小工具分享。',
			keywords: 'web前端，JavaScript，jQuery，Vue，HTML，CSS，PHP，MySQL，thinkPHP，IIS'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

//全局导航守卫，前置钩子，在页面跳转前完成
router.beforeEach((to, from, next) => {
	//为每个页面添加标题，描述和关键字
	document.title = to.meta.title || '小寅编程笔记'
	const meta = []
	if (to.meta.description) meta.push({
		name: 'description',
		content: to.meta.description
	})
	if (to.meta.keywords) meta.push({
		name: 'keywords',
		content: to.meta.keywords
	})
	// 清除之前的meta标签
	document.querySelectorAll('meta[name="description"], meta[name="keywords"]').forEach((el) => {
		el.remove()
	})
	// 添加新的meta标签
	meta.forEach(({
		name,
		content
	}) => {
		const metaTag = document.createElement('meta')
		metaTag.setAttribute('name', name)
		metaTag.setAttribute('content', content)
		document.head.appendChild(metaTag)
	})

	setTimeout(() => {
		var _hmt = _hmt || [];
		(function() {
			//每次执行前，先移除上次插入的代码
			document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
			var hm = document.createElement("script");
			hm.src = "https://hm.baidu.com/hm.js?2719bac3f028c2bdb298ebf67ba5ae82";
			hm.id = "baidu_tj"
			var s = document.getElementsByTagName("script")[0];
			s.parentNode.insertBefore(hm, s);
		})();
	}, 0);

	next()
})

//全局导航守卫，后置钩子，在页面跳转前完成
router.afterEach((to, from) => {

})

export default router